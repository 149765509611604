<template>
  <div class="container repair-container">
    <div class="row">
      <div class="col-md-12">
        <!-- Alert -->
        <transition name="fade" mode="out-in">
          <div v-if="state === 'pending'" class="alert alert-warning">
            <animated-warning/>
            <div class="alert-content">
              <strong>There is an outstanding payment required for this repair before you can continue</strong>
            </div>
          </div>
        </transition>
        <transition name="fade" mode="out-in" v-if="errmsg && errmsg.length > 0">
          <template v-for="msg in errmsg" >
          <div class="alert alert-danger" :key="msg">

            <animated-error/>
            <div class="alert-content">
              <strong>{{msg}}</strong>
            </div>
            </div>
          </template>

        </transition>
      </div>
    </div>

    <skip-payment
        :payment="payment"
        :is-loading="isLoading"
        v-if="skipping"
        @clickedSkip="skipPayment"
      />

    <pending-payment
        :payment="payment"
        :is-loading="isLoading"
        v-else-if="state === 'pending'"
        @clickedCancel="bailRepair"
        @clickedEmail="emailPaymentLink"
    />

    <await-payment
        :payment="payment"
        :last-sent-email-address="sentToEmailAddress"
        :is-loading="isLoading"
        v-else-if="state === 'email_sent'"
        @clickedCancel="bailRepair"
        @clickedEmail="emailPaymentLink"
    />

    <declined-payment
        :can-cancel="!job || !job.in_warranty"
        :payment="payment"
        :is-loading="isLoading"
        v-else-if="state === 'declined'"
        @clickedCancel="bailRepair"
    />

    <paid-payment
        :payment="payment"
        :is-loading="isLoading"
        v-else-if="state === 'paid'"
      />
  </div>
</template>

<script>
import PendingPayment from "./states/PendingPayment";
import {mapActions, mapGetters} from "vuex";
import AwaitPayment from "./states/AwaitPayment";
import DeclinedPayment from "./states/DeclinedPayment";
import PaidPayment from "./states/PaidPayment";
import SkipPayment from "./states/SkipPayment";

export default {
  components: {SkipPayment, PaidPayment, DeclinedPayment, AwaitPayment, PendingPayment},

  data() {
    return {
      // When we fist thought about creating Door Step Payments applying a discount on cancelling seemed like a good
      // idea. Having put more thought into it, Neil has suggested it might give the customer a bad taste, therefore
      // we are dropping the mechanism for now. However, if we wish to apply it in the future it's enabled by the
      // below applyDiscount flag.
      applyDiscount: false,

      // This state is calculated based upon the status of the payment record in Firebase:
      //   - pending -> pending OR cancelling (if applyDiscount true and discount_percentage > 0)
      //   - email_sent
      //   - paid
      //   - declined
      //   - complete
      //   - skipped
      //
      // The complete status is largely ignored by this component because it being set involves the PaymentGateway
      // not displaying.
      state: 'pending',

      // Are we communicating with the API?
      isLoading: false,

      // The email address we last sent a payment email to.
      sentToEmailAddress: null,

      // Are we in the process of skipping the doorstep payment?
      skipping: false,

      //error messages for alets
      errmsg: null,
    }
  },

  computed: {
    ...mapGetters([
      "device",
      "deviceStatus",
      "job",
      "paymentForDevice",
    ]),
    payment() {
      return this.paymentForDevice(Number(this.$route.params.deviceId))
    }
  },

  watch: {
    payment(value) {
      this.state = value.status;
      if (this.sentToEmailAddress === null) {
        this.sentToEmailAddress = value.email;
      }
    },
  },

  mounted() {
    this.state = this.payment.status;
    if (this.sentToEmailAddress === null) {
      this.sentToEmailAddress =  this.payment.email;
    }
  },

  methods: {
    ...mapActions([
      "setDeviceStatus",
    ]),

    async bailRepair({reason}) {
      if (reason === 'cancel') {
        return this.cancelRepair();
      }
      this.skipping = true;
    },

    async skipPayment({reason}) {
      const {jobId} = this.$route.params;
      this.errmsg = null;
      this.isLoading = true;

      try {
        await this.$http.patch(`api/v4/jobs/${jobId}/doorstep-payments/skip`, {reason});
        this.isLoading = false;
      } catch(e) {
        // TODO Display error, inform technician to contact support to continue
        this.errmsg = e.response.data.messages;
        this.isLoading = false;
        console.log('error', e);
      }
    },

    async cancelRepair() {
      const {jobId, deviceId} = this.$route.params;
      this.errmsg = null;
      this.isLoading = true;
      if (this.payment.discount_percentage > 0 || this.applyDiscount === false) {
        // Customer has already been presented with the discount, they have still declined

        await this.setDeviceStatus({
          jobId,
          deviceId,
          status: 43, // Cannot Repair
          subStatusId: 130, // Customer Declined OOW Charge
          completeDescription: 'Customer has declined to pay for repair.'
        })

        this.$router.push({name: 'job', params: {jobId}})

        return;
      }

      // ELSE Request a discount via API
      try {
        await this.$http.patch(`api/v4/jobs/${jobId}/doorstep-payments/apply-discount`);
        this.isLoading = false;
      } catch(e) {
        // TODO Display error, inform technician to contact support to continue
        this.errmsg = e.response.data.messages;
        this.isLoading = false;
        console.log('error', e);
      }
    },

    async emailPaymentLink({email}) {
      const {jobId} = this.$route.params;
      this.errmsg = null;
      this.isLoading = true;
      this.sentToEmailAddress = email;
      try {
        await this.$http.patch(`api/v4/jobs/${jobId}/doorstep-payments/send-payment-email`, {email});
        this.isLoading = false;
      } catch(e) {
        // TODO Display error, inform technician to contact support to continue
        this.errmsg = e.response.data.messages;
        this.isLoading = false;
        console.log('error', e);
      }
    }
  }
}
</script>