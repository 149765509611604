<template>
    <div>
        <slot/>

        <button :disabled="disabled" class="btn btn-success btn-lg btn-block" @click="$emit('clickedSuccess')">{{ successBtnText }}</button>

        <div v-if="canCancel" class="or-line my-3">OR</div>
<!--        <div v-if="canCancel" class="d-flex flex-row">-->
<!--            <button :disabled="disabled" class="btn btn-warning btn-lg btn-block" @click="$emit('clickedCancel', {reason: 'cancel'})">Cancel Repair</button>-->
<!--            <button :disabled="disabled" class="btn btn-warning btn-lg btn-block mt-0 ml-2" @click="$emit('clickedCancel', {reason: 'skip'})">Skip Payment</button>-->
<!--        </div>-->
<!--        <button v-else :disabled="disabled" class="btn btn-warning btn-lg btn-block mt-0" @click="$emit('clickedCancel', {reason: 'skip'})">Skip Payment</button>-->
        <button v-if="canCancel" :disabled="disabled" class="btn btn-warning btn-lg btn-block mt-0" @click="$emit('clickedCancel', {reason: 'skip'})">Skip Payment</button>
    </div>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        canCancel:{
            type: Boolean,
            default: false,
        },
        successBtnText: {
            type: String,
            default: 'Continue'
        }
    }
}
</script>

<style scoped>
.or-line{
    text-align: center;
    position: relative;

}
.or-line:before{
    display: block;
    content: "";
    background: #686f92;
    height: 1px;
    width: 47%;
    position: absolute;
    top: 50%;
}
.or-line:after{
    display: block;
    content: "";
    background: #686f92;
    height: 1px;
    width: 47%;
    position: absolute;
    top: 50%;
    right: 0;
}
</style>