<template>
    <div class="repair-process" v-if="job && canViewJob">
        <!-- Progress Bar -->
        <progress-steps :deviceStatus="deviceStatus"></progress-steps>

        <!-- Alerts -->
        <div class="alert alert-danger alert-centered cursor-pointer rounded-0 mb-0" v-if="soWarrantyErrors.length > 0" @click="showSoWarrantyErrorModal">
            <animated-error></animated-error> {{ soWarrantyErrors.length }} errors on GSPN. Tap here to view
        </div>

        <div class="alert alert-danger alert-centered cursor-pointer rounded-0 mb-0" v-if="device && device.status_title === 'On Hold'">
            <animated-error></animated-error> Likewize have placed this device ON HOLD. Please contact technical support before continuing.
        </div>

        <!-- Content -->
        <transition name="fade" mode="out-in">
            <payment-gateway v-if="hasPendingPaymentForDevice"/>
            <router-view v-else/>
        </transition>

        <!-- Device Status Modal -->
        <update-device-status-modal v-if="job && deviceId" :deviceId="deviceId" :jobId="jobId"/>

        <!-- Note Modal -->
        <note-modal v-if="job && deviceId" :jobId="jobId" :noteableId="deviceId" :noteableType="'device'"/>

        <!-- SO Warranty Error Modal -->
        <so-warranty-error-modal :soWarrantyErrors="soWarrantyErrors"/>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import PaymentGateway from "../../components/repairProcess/paymentGateway/PaymentGateway";

    export default {
      components: {PaymentGateway},
      props:['deviceId','jobId'],

        mounted() {
            this.fetchJobData();
        },

        watch: {
            jobId(jobId) {
                if (jobId) {
                    this.fetchJobData();
                }
            }
        },

        computed: {
            ...mapGetters([
                "device",
                "deviceStatus",
                "isTechnician",
                "isJobManager",
                "isInHouseTechnician",
                "job",
                "payments",
                "paymentForDevice",
                "jobNotes",
                "soWarrantyErrors",
                "user",
                "isRefurbOrMailIn"
            ]),

            hasPendingPaymentForDevice() {
              return this.payment && !['complete', 'skipped'].includes(this.payment.status);
            },

            payment() {
              return this.paymentForDevice(Number(this.deviceId))
            },

            /** Check if the user can view the job */
            canViewJob() {
                if (this.isJobManager) {
                    return true;

                } else if (this.isInHouseTechnician && this.isRefurbOrMailIn) {
                    return true;

                } else {
                    return (this.user.technician && this.user.technician.id === this.job.technician_id);
                }
            },
        },

        methods: {
            ...mapActions([
                "loadJob",
                "loadDeviceForJob",
                "loadDeviceStatus",
                "loadJobNotes",
                "loadSoWarrantyErrors",
                "loadDoorstepPaymentsForJob"
            ]),

            /** Fetch the order id and devices */
            async fetchJobData() {
              await this.loadJob({jobId: this.jobId});

              await Promise.all([
                this.loadDoorstepPaymentsForJob({jobId: this.jobId}),
                this.loadDeviceForJob({jobId: this.jobId, deviceId: this.deviceId})
              ]);

              await Promise.all([
                this.loadDeviceStatus({statusId: this.device.status_id}),
                this.loadSoWarrantyErrors({jobId: this.jobId, deviceId: this.deviceId})
              ])
            },

            /** Show the GSPN errors modal */
            showSoWarrantyErrorModal() {
                this.$bvModal.show('so-warranty-error-modal');
            },
        }
    }
</script>
