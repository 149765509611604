<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <h3 class="text-center pt-3">Payment Complete</h3>

          <div class="py-5 d-flex justify-content-center">
            <animated-success size="6x"/>
          </div>

          <p>The customer's payment for this repair has been approved, you may now continue with the repair.</p>

          <hr/>

          <button :disabled="isLoading || isContinuing" class="btn btn-success btn-lg btn-block" @click="actionContinue">Continue</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payment: {
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {isContinuing: false}
  },

  methods: {
    async actionContinue() {
      const {jobId} = this.$route.params;
      this.isContinuing = true;
      try {
        await this.$http.patch(`api/v4/jobs/${jobId}/doorstep-payments/complete`);
      } catch(e) {
        // TODO Display error, inform technician to contact support to continue
        console.log('error', e);
      }
    }
  }
}
</script>

<style scoped>
  p {
    text-align: center;
    font-size: 1.3em;
  }
</style>
