<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <h3 class="text-center pt-3">Skip Payment</h3>
          <p>
            Please select a reason for skipping payment.
          </p>

          <div class="d-flex flex-row">
            <button :disabled="isLoading" class="mr-1 btn btn-success btn-lg btn-block" @click="$emit('clickedSkip', {reason: 'requires-saw'})">Device Requires SAW</button>
            <button :disabled="isLoading" class="ml-1 mt-0 btn btn-success btn-lg btn-block" @click="$emit('clickedSkip', {reason: 'iw-repair'})">Device is covered under Samsung warranty - Proceed repair as IW</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payment: {
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped>
  p {
    text-align: center;
    font-size: 1.3em;
  }
</style>
