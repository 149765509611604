<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="text-center pt-3">Payment Declined</h3>

                    <div class="py-5 d-flex justify-content-center">
                        <animated-error size="6x"/>
                    </div>

                    <p class="text-center">There was an issue taking payment. Please ask the customer to make payment via alternative means. Alternatively they can choose to cancel this repair.</p>

                    <hr/>

<!--                    <button v-if="canCancel" :disabled="isLoading" class="btn btn-warning btn-lg btn-block" @click="$emit('clickedCancel', 'cancel')">Cancel Repair</button>-->
<!--                    <div v-if="canCancel" class="or-line my-3">OR</div>-->
                    <button :disabled="isLoading" class="btn btn-warning btn-lg btn-block" @click="$emit('clickedCancel', {reason: 'skip'})">Skip Payment</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        payment: {
            required: true,
        },
        canCancel:{
            type: Boolean,
            default: false,
        },
        isLoading: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>
.or-line{
    text-align: center;
    position: relative;

}
.or-line:before{
    display: block;
    content: "";
    background: #686f92;
    height: 1px;
    width: 47%;
    position: absolute;
    top: 50%;
}
.or-line:after{
    display: block;
    content: "";
    background: #686f92;
    height: 1px;
    width: 47%;
    position: absolute;
    top: 50%;
    right: 0;
}
</style>