<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <h3>Payment Required</h3>
                    <p v-if="payment.discount_percentage > 0" >
                        We are able to provide a {{ payment.discount_percentage }}% discount on this repair if the customer is able
                        to pay today.<br/>This discount will not be transferable to any revisits.
                    </p>
                    <p v-else>
                        This device has OOW repairs. Before you can continue this repair the customer will need to make payment
                        for the below line items:
                    </p>

                    <line-items :total-amount="payment.totalAmount"
                                :items="payment.items"
                                :discount-percentage="payment.discount_percentage"/>

                    <hr/>

                    <payment-button-group
                        :can-cancel="isJobManager"
                        :disabled="isLoading"
                        success-btn-text="Send Payment Email"
                        @clickedCancel="$emit('clickedCancel', $event)"
                        @clickedSuccess="$emit('clickedEmail', {email: emailAddress })"
                    >
                        <p>In order to make payment we can email the customer a payment link, alternatively they can choose to cancel this repair.</p>
                        <div class="form-group">
                            <label>Email Address</label>
                            <input type="text" class="form-control" v-model="emailAddress">
                        </div>
                    </payment-button-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentButtonGroup from "../PaymentButtonGroup";
import LineItems from "../LineItems";
import {mapGetters} from "vuex";
export default {
    components: {LineItems, PaymentButtonGroup},
    props: {
        payment: {
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            emailAddress: this.payment.email
        }
    },

    computed: {
        ...mapGetters(['job', 'isJobManager'])
    }
}
</script>