<template>
  <div>
    <ul class="list-group mb-2">
      <li v-for="(item, k) in items" :key="`line-item-${k}`" class="list-group-item flex flex-row">
        <span class="flex-grow-1">{{ item.title }}</span>
        {{ item.price }}
      </li>
      <li v-if="discountPercentage > 0" class="list-group-item flex flex-row">
        <strong class="flex-grow-1">Discount</strong>
        <strong>{{ discountPercentage }}%</strong>
      </li>
    </ul>

    <h4 class="text-right pr-3">Total: {{ totalAmount }}</h4>
  </div>
</template>

<script>
export default {

  props: {
    discountPercentage: {
      type: Number,
      default: 0,
    },
    items: {
      required: true,
      type: Array,
    },
    totalAmount: {
      type: String,
      required: true
    }
  }

}
</script>