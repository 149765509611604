<template>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="text-center pt-3">Awaiting Payment Completion</h3>

                    <div class="py-5 d-flex justify-content-center">
                        <animated-loading size="6x"/>
                    </div>

                    <p>An email has been sent to the customer containing a link for completing payment. You can use the button below to resend the email.</p>

                    <hr/>

                    <payment-button-group
                        :can-cancel="isJobManager"
                        :disabled="isLoading"
                        success-btn-text="Resend Payment Email"
                        @clickedCancel="$emit('clickedCancel', $event)"
                        @clickedSuccess="$emit('clickedEmail', {email: emailAddress })">
                        <div class="form-group">
                            <label>Email Address</label>
                            <input type="text" class="form-control" v-model="emailAddress">
                        </div>
                    </payment-button-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentButtonGroup from "../PaymentButtonGroup";
import {mapGetters} from "vuex";
export default {
    components: {PaymentButtonGroup},
    props: {
        payment: {
            required: true,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        lastSentEmailAddress: {
            type: String
        }
    },

    data() {
        return {
            emailAddress: this.lastSentEmailAddress
        }
    },

    computed: {
        ...mapGetters(['job', 'isJobManager'])
    },

    watch: {
        lastSentEmailAddress(value) {
            this.emailAddress = value
        }
    }
}
</script>
